import * as React from 'react'
import { FCProps } from '../../interfaces/components'
import Footer from '../Footer/index'
import Header from '../Header'
import Main from '../Main/index'

const Layout: React.FunctionComponent<FCProps> = ({ children }) => (
    <div className="container bg-mimo font-mimo mx-auto">
        <Header />
        <Main>{children}</Main>
        <Footer />
    </div>
)

export default Layout
