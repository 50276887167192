import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

const Footer = () => (
    <footer className="p-4">
        <div className="flex space-x-2">
            <a href="https://www.instagram.com/mimomatosi/" target="_blank">
                <FontAwesomeIcon icon={faInstagram} />
            </a>
            <p>Mimo Matosi</p>
        </div>
    </footer>
)

export default Footer
