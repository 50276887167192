import * as React from 'react'
import { Link } from 'gatsby'

const Header = () => (
    <header className="containerp pt-2 pb-4">
        <div className="flex flex-col justify-items-center items-center">
            <img
                className="block logo place-items-center items-center w-20"
                src={'/logo4.png'}
                alt="Workflow"
            />
            <nav className="flex space-x-2 p-2 text-s font-mimo">
                <Link to="/">Start</Link>
                <Link to="/impressum">Impressum</Link>
            </nav>
        </div>
    </header>
)

export default Header
